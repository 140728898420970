.swiper-container {
    width: 100%;
    height: 8rem;
}

$swiper-margin: 2rem;

.swiper-container-container {
    position: relative;
    margin: 0 $swiper-margin $swiper-margin;
}

.swiper-slide {
    text-align: center;
    font-size: rem(18px);
    background: rgb(232, 232, 232);

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    flex-direction: column;
}

.swiper-pagination-resources, .swiper-pagination-news, .swiper-pagination-events {
    width: 100%;
    .swiper-pagination-bullet {
        margin: 0 0.1rem;
        &-active {
            background: color("primary-dark");
        }
    }
}

.swiper-button-prev-resources, .swiper-button-prev-news, .swiper-button-prev-events {
    left: -$swiper-margin!important;
}

.swiper-button-next-resources, .swiper-button-next-news, .swiper-button-next-events {
    right: -$swiper-margin!important;
}

.swiper-button-prev-resources, .swiper-button-prev-news, .swiper-button-prev-events,
.swiper-button-next-resources, .swiper-button-next-news, .swiper-button-next-events {
    transform: scale(0.5);
    &:hover {
        transform: scale(0.6);
    }
}

.resources-swiper, .news-swiper, .events-swiper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 1rem 0 3rem;
}