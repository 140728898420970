/*!
 * Theme Name: MPC 2020
 * Description: Starter Theme to use with Timber
 * Author: BlaineTurner Advertising
*/


/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
*/

// -------------------------------------
// Import individual theme settings

@import "uswds/uswds-theme-general";
@import "uswds/uswds-theme-typography";
@import "uswds/uswds-theme-spacing";
@import "uswds/uswds-theme-color";
@import "uswds/uswds-theme-utilities";

// components import needs to be last
@import "uswds/uswds-theme-components";

// -------------------------------------
// Import individual USWDS packages...
// See https://designsystem.digital.gov/components/

// First import required and global packages...
// @import 'packages/required';
// @import 'packages/global';

// Then import individual component packages...
// @import 'packages/form-controls';
// @import 'packages/form-templates';
// @import 'packages/layout-grid';
// @import 'packages/typography';
// @import 'packages/validation';
// @import 'packages/usa-accordion';
// @import 'packages/usa-alert';
// @import 'packages/usa-banner';
// @import 'packages/usa-breadcrumb';
// @import 'packages/usa-button';
// @import 'packages/usa-button-group';
// @import 'packages/usa-card';
// @import 'packages/usa-checklist';
// @import 'packages/usa-footer';
// @import 'packages/usa-header';
// @import 'packages/usa-hero';
// @import 'packages/usa-layout';
// @import 'packages/usa-media-block';
// @import 'packages/usa-megamenu';
// @import 'packages/usa-nav-container';
// @import 'packages/usa-nav';
// @import 'packages/usa-navbar';
// @import 'packages/usa-section';
// @import 'packages/usa-search';
// @import 'packages/usa-sidenav';
// @import 'packages/usa-skipnav';
// @import 'packages/usa-table';
// @import 'packages/usa-tag';

// or package sets...
// @import 'packages/uswds-components';
// @import 'packages/uswds-utilities';

// -------------------------------------
// ...or import the complete USWDS kit

@import "uswds";

// -------------------------------------
// Import theme custom styles

@import "uswds-theme-custom-styles";