.usa-navbar {
   .usa-menu-btn {
      background-color: transparent;
      color: #5A5555;
      // svg {
      //    width: 1.1rem;
      //    height: 2rem;
      //    path {
      //       fill: #5A5555;
      //    }
      // }
   }
}

.usa-megamenu a {
   transition: background-color 0s!important;
}

/* The Contrast Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.55rem;
    .slider {
       position: absolute;
       cursor: pointer;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       background-color: #b7b9bd;
       transition: background-color .25s ease-out;
       &:before {
          position: absolute;
          content: "";
          height: 1rem;
          width: 1rem;
          left: 0.25rem;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          background-color: white;
          transition: transform .25s ease-out;
       }
       &.round {
          border-radius: 2rem;
          &:before {
             border-radius: 50%;
          }
       }
    }
    input {
       opacity: 0;
       width: 0;
       height: 0;
       &:checked + .slider {
          background-color: color("primary-dark");
          &:before {
             transform: translateX(1.4rem);
          }
       }
       &:focus + .slider {
          outline: .2222222222rem solid #2491ff;
          outline-offset: 0;
       }
    }
}

.usa-nav__submenu-item-submenu {
   list-style: none;
   padding-left: 1rem;
}

.usa-nav__secondary {
   .usa-nav__secondary-links {
      .login-button {
         position: relative;
         text-transform: uppercase;
         color: color("primary");
         border: 1px solid color("primary");
         transition: border-color $global-transition, color $global-transition, background-color $global-transition;
         border-radius: 4px;
         padding: .5rem 1rem .5rem 1.75rem;
         font-size: 0.75rem;
         &:hover {
            color: color("primary-dark");
            background-color: transparentize(color("primary-dark"), 0.98);
            border-color: color("primary-dark");
            text-decoration: none;
         }
         &:before {
            content: "";
            position: absolute;
            left: 1rem;
            top: 0;
            bottom: 0;
            width: 0.45rem;
            background-image: url('/wp-content/uploads/2020/09/lock.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
         }
      }
   }
}

.usa-nav__secondary__sub {
   ul {
      @include add-list-reset;
      li {
         display: flex;
         button {
            cursor: pointer;
         }
         #text-switch-0 {
            &.active {
               pointer-events: none;
               background-color: color('primary-dark')!important;
            }
         }
         #text-switch-1 {
            font-size: 140%;
         }
         #text-switch-2 {
            font-size: 175%;
         }
         &.contrast-toggle {
            align-items: center;
            p {
               margin-right: 0.3rem;
               color: color("primary-dark");
               font-weight: 500;
               font-size: 0.8rem;
               cursor: pointer;
            }
         }
         &.larger-text-buttons {
            margin-right: 0.5rem;
            & > ul {
               align-items: baseline;
               li {
                  margin-top: 0;
                  margin-bottom: 0;
               }
            }
            button {
               margin-left: 5px;
               display: flex;
               align-items: flex-end;
               color: color("primary-dark");
               font-weight: bold;
               background-color: white;
               border: none;
               border-radius: 4px;
               &.active {
                  background-color: color('primary-dark');
                  color: white;
               }
               &:hover {
                  background-color: color('primary');
                  color: white;
               }
            }
         }
         &.language {
            select {
               appearance: none;
               -webkit-appearance: none;
               -moz-appearance: none;
               border: none;
               font-weight: 500;
               padding: 0.25rem 1rem 0.25rem 0.55rem;
               color: color("primary-dark");
               background-image: url('/wp-content/themes/mpc-timber/static/img/angle-arrow-down-primary.svg');
               background-position: right 0.5rem center;
               background-size: 0.5rem;
               background-repeat: no-repeat;
               cursor: pointer;
               /* For IE <= 11 */
               select::-ms-expand {
                  display: none;
               }
            }
         }
      }
   }
}

@include at-media-max($theme-header-min-width) {
   .usa-nav__secondary__sub {
      ul {
         li {
            margin-bottom: 10px;
         }
      }
   }
   .usa-megamenu .usa-col {
      flex: 1 1 100%;
   }
   .usa-nav__submenu a {
      padding-left: 1.25rem;
   }
   .usa-navbar {
      min-height: 65px;
   }
   .usa-logo {
      height: 85%;
      display: flex;
      justify-content: center;
      a {
         height: 100%;
         right: 10px;
         position: relative;
         svg {
            height: 100%;
            padding: 1px;
         }
      }
   }
}

@include at-media($theme-header-min-width) {
   .usa-logo a {
      display: inline-flex;
      width: 250px;
      svg {
         width: 100%;
         height: 100%;
      }
   }

   .usa-nav__submenu-item-submenu {
      // margin-top: .5rem;
      // border-left: 3px solid color("primary-dark");
      // border-radius: 3px;
   }

   .usa-header--extended .usa-nav {
      border: none;
   }

   .usa-header--basic .usa-nav-container {
      align-items: center;
   }

   .usa-nav {
      .usa-search {
         width: initial;
         .usa-input {
            width: 5ch;
            transition: width 0.4s ease-in-out;
            &:focus {
               width: 27ch;
            }
         }
      }
   }

   // ul.usa-nav__primary {
   //    .usa-nav__primary-item {
   //       margin-right: 10px;
   //       & > a, button {
   //          background-color: color("primary");
   //          border-radius: 6px 6px 0 0;
   //          color: white!important;
   //          font-family: proxima-soft, sans-serif;
   //          &[aria-expanded=false] {
   //             background-image: url('./static/img/angle-arrow-down-white.svg'),linear-gradient(transparent,transparent);
   //          }
   //          &[aria-expanded=true] {
   //             &:before {
   //                content: "";
   //                position: absolute;
   //                bottom: 0;
   //                right: 15%;
   //                z-index: 1;
   //                border: 8px solid transparent;
   //                border-bottom-color: #EAEDF0;
   //             }
   //          }
   //          &:after {
   //             background-color: #EAEDF0!important;
   //             display: none!important;
   //          }
   //          &:hover {
   //             background-color: color("primary-dark");
   //          }
   //       }
   //    }
   // }

   .usa-nav__secondary {
      bottom: units(15);
      display: flex;
      align-items: center;
      font-family: proxima-soft, sans-serif;
      .usa-nav__secondary-links {
         margin-bottom: 0;
         transition: opacity 0.25s ease-in-out;
         &.hide {
            opacity: 0;
         }
      }
      .usa-search {
         margin-top: 0!important;
         margin-left: 10px;
      }
   }

   .usa-nav__secondary__sub {
      bottom: units(8);
      font-family: proxima-soft, sans-serif;
      ul {
         @include add-list-reset;
         display: flex;
         width: 100%;
         justify-content: flex-end;
         li.larger-text-buttons {
            margin-left: 0.75rem;
         }
      }
   }

   .usa-megamenu.usa-nav__submenu,
   .usa-megamenu.usa-nav__submenu::before,
   .usa-megamenu.usa-nav__submenu::after {
      background-color: #EAEDF0;
   }

   .usa-megamenu.usa-nav__submenu {
      .usa-nav__submenu-item {
         a {
            color: color("primary");
            font-size: 1rem;
            font-family: proxima-soft, sans-serif;
            font-weight: 500;
            padding-left: 0.55rem;
            padding-right: 0.55rem;
            padding-top: 0.15rem;
            padding-bottom: 0.15rem;
            border: 0.05rem dotted transparent;
            border-left: 0.5rem solid transparent;
            display: inline-block;
            &:hover, &:focus {
               background-color: white;
               border: 0.05rem dotted #707070;
               border-left: 0.5rem solid $mpc-red;
               text-decoration: none;
            }
         }
         &-submenu {
            padding-left: 0;
            li {
               position: relative;
               padding-left: 0.85rem;
               &::before {
                  content: "";
                  position: absolute;
                  left: 1.85rem;
                  top: 0;
                  bottom: 0;
                  margin-top: auto;
                  margin-bottom: auto;
                  width: 0.45rem;
                  height: 0.45rem;
                  background-color: color("primary-dark");
               }
               a {
                  padding-left: 1.5rem;
               }
            }
         }
      }
      &.for-members-submenu {
         & > div {
            position: relative;
            &:after {
               content: "";
               position: absolute;
               bottom: -1.7777777778rem;
               right: 0.25em;
               background-image: url('/wp-content/uploads/2020/09/for-members.svg');
               background-size: contain;
               background-repeat: no-repeat;
               background-position: right center;
               height: 5em;
               width: 28em;
            }
         }
      }
      .megamenu-ad {
         ul {
            margin-left: -1em;
            margin-top: -1em;
            margin-bottom: -1em;
         }
      }
   }
}

//-------------//
// Sidebar Nav //
//-------------//

ul.sidebar-nav {
   list-style: none;
   padding: 0;
   a {
      text-decoration: none;
      padding: 0.35rem 1rem 0.35rem 0.5rem;
      border-left: .75rem solid transparent;
      display: block;
      transition: $global-transition;
      color: color("primary-dark");
      & > span {
         line-height: 1.35;
         display: block;
      }
   }
   & > li {
      padding: 2px 0 1px;
      &.active {
         & > a {
            font-size: 1.15rem;
            font-weight: 500;
            pointer-events: none;
         }
      }
      &:not(.active) {
         & > a {
            &:not(.parent-link) {
               background-color: rgba(242, 242, 242, 1);
               &:hover {
                  background-color: rgba(250, 250, 250, 1);
                  background-color: transparentize($mpc-blue-dark, 0.925);
               }
            }
         }
      }
   }
   ul.sidebar-sub-nav {
      list-style: none;
      // padding-left: 1rem;
      padding-left: 0;
      & > li {
         padding: 2px 0 1px;
         &.active {
            & > a {
               font-size: 1.15rem;
               font-weight: 500;
               pointer-events: none;
            }
         }
         &:not(.active) {
            & > a {
               border: 1px solid rgba(112, 112, 112, 0.15);
               // margin-left: 0.75rem;
               padding: 0.25rem 1em 0.25rem 1.25rem;
               &:hover {
                  // border-color: rgba(112, 112, 112, 0.25);
                  background-color: transparentize($mpc-blue-dark, 0.975);
                  border: 1px solid rgba(26, 68, 128, 0.15);
                  border: 1px solid transparentize($mpc-blue-dark, 0.85);
               }
            }
         }
      }
   }
   a.parent-link {
      color: white;
      background-color: color("primary-dark");
      border-left: .75rem solid $mpc-red;
      font-weight: bold;
      padding: .5rem 1em .5rem 0.5rem;
      margin-bottom: 0.15rem;
      font-size: 1.15rem;
      transition: background-color $global-transition;
      &:hover {
         background-color: color("primary");
      }
   }
   ul.jump-links {
      list-style: none;
      padding-left: 1rem;
      & > li {
         position: relative;
         border-top: 1px solid rgba(112, 112, 112, 0.5);
         &:before {
            content: "\00BB";
            position: absolute;
            left: 0.3rem;
            top: -0.3rem;
            font-size: 1.25rem;
            bottom: 0;
            display: flex;
            align-items: center;
            color: color("primary-dark");
         }
         & > a {
            font-size: 0.95rem;
            &:hover {
               background-color: transparentize($mpc-blue-dark, 0.975);
            }
         }
      }
   }
   @include at-media('tablet') {
      padding-right: 1.5rem;
   }
}

#breadcrumbs {
   a {
      color: color("primary-dark");
      text-decoration: none;
      strong {
         font-size: 1.75rem;
      }
   }
   & > span {
      // This is the first link
      width: 100%;
      display: block;
      line-height: 1.1;
      & > a {
         font-family: $museo;
         font-size: 1.15rem;
         text-transform: lowercase;
      }
   }
   .breadcrumb_last {
      display: inline-block;
   }
}