/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS THEME CUSTOM STYLES
----------------------------------------
!! Copy this file to your project's
   sass root. Don't edit the version
   in node_modules.
----------------------------------------
Custom project SASS goes here.

i.e.
@include u-padding-right('05');
----------------------------------------
*/

$proxima: proxima-soft, sans-serif;
$museo: museo-slab, serif;

.proxima {
   font-family: $proxima!important;
}

.museo {
   font-family: $museo!important;
}

$mpc-red: #f32735;
$mpc-blue: color("primary");
$mpc-blue-dark: color("primary-dark");

$global-transition: 0.15s ease-in-out;

@function rem($size) {
   $remSize: $size / $theme-root-font-size;
   @return #{$remSize}rem;
}

@mixin fontSize($size) {
   font-size: $size; //Fallback in px
   font-size: rem($size);
}

html {
   font-size: $theme-root-font-size; // 18px
   &.high-contrast {
      filter: contrast(1.5);
      img {
         filter: contrast(0.75);
      }
      body {
         // background-color: black;
         // color: yellow;
      }
   }
   &.larger-text-1 {
      font-size: calc(#{$theme-root-font-size} * 1.16); // 21px
   }
   &.larger-text-2 {
      font-size: calc(#{$theme-root-font-size} * 1.4); // 25px
   }
   font-family: $proxima;
   a {
      transition: color $global-transition, background-color $global-transition;
   }
}

h1 {
   font-size: size('heading', $theme-h1-font-size);
   line-height: line-height('heading', 1);
}
h2 {
   font-size: size('heading', $theme-h2-font-size);
   line-height: line-height('heading', 1);
}
h3 {
   font-size: size('heading', $theme-h3-font-size);
   line-height: line-height('heading', 2);
}
h4 {
   font-size: size('heading', $theme-h4-font-size);
   line-height: line-height('heading', 2);
}
h5 {
   font-size: size('heading', $theme-h5-font-size);
   line-height: line-height('heading', 3);
}
h6 {
   font-size: size('heading', $theme-h6-font-size);
   line-height: line-height('heading', 3);
}

// Swiper Styles
@import "swiper";
// Menu Styles
@import "menus";
// Widget Styles
@import "widgets";

/* Text meant only for screen readers. */
.screen-reader-text {
   border: 0;
   clip: rect(1px, 1px, 1px, 1px);
   clip-path: inset(50%);
   height: 1px;
   margin: -1px;
   overflow: hidden;
   padding: 0;
   position: absolute;
   width: 1px;
   word-wrap: normal !important;
   &:focus {
      background-color: #eee;
      clip: auto !important;
      clip-path: none;
      color: #444;
      display: block;
      font-size: 1em;
      height: auto;
      left: 5px;
      line-height: normal;
      padding: 15px 23px 14px;
      text-decoration: none;
      top: 5px;
      width: auto;
      z-index: 100000; /* Above WP toolbar. */
   }
}

.usa-footer__social-links {
   justify-content: flex-start;
}

.usa-hero__fixed {
   height: 20em;
   background-repeat: no-repeat;
   background-position: left -85vw bottom 20vh;
   background-attachment: fixed;
   background-size: auto 100%;
   @include at-media($theme-header-min-width) {
      height: 25em;
      background-position: center bottom 10vh;
      background-size: cover;
   }
}

$bottom-nav-height: 3rem;

body {
   padding-bottom: $bottom-nav-height;
   font-family: $proxima;
   nav#bottom-nav {
      height: $bottom-nav-height;
      border-top: 1px solid rgba(0, 0, 0, 0.6);
      background-color: rgba(245, 245, 245, 0.98);
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 1rem;
      z-index: 499;
      ul {
         display: flex;
         height: 100%;
         li {
            flex: 1;
            a {
               display: flex;
               flex-direction: column;
               align-items: center;
               justify-content: center;
               font-size: 0.6em;
               line-height: 1.15;
               color: rgba(0, 0, 0, 0.85);
               height: 100%;
               width: 100%;
               padding: 4px 8px;
               text-align: center;
               text-decoration: none;
               transition: $global-transition;
               img {
                  max-height: rem(24px); // needs to stay the same size, can't be rem
                  height: 100%;
                  width: auto;
                  margin-bottom: auto;
               }
               p {
                  margin: 0.1rem 0 0 0;
               }
               &:hover, &:focus, &:active {
                  background-color: rgba(255, 255, 255, 1);
               }
            }
         }
      }
   }
   @media (min-width: 480px) {
      padding-bottom: initial;
      nav#bottom-nav {
         display: none;
      }
   }
}

header.usa-header {
   box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

section.usa-hero {
   position: relative;
   // z-index: -1; // Show nav's box shadow when hero has background image
   .grid-container {
      position: relative;
      z-index: 1;
      height: 100%;
   }
   .swoosh-container {
      // position: relative;
      svg.hero-swoosh {
         position: absolute;
         max-height: 10em;
         width: 100%;
         bottom: 0;
         left: 0;
         right: 0;
         z-index: 0;
      }
      svg.hero-square-swoosh {
         z-index: 0;
         position: absolute;
         bottom: -7.5vw;
         left: 0;
         right: 0;
         max-height: 22rem;
         min-width: 35rem;
      }
   }
   &.basic {
      background-color: color('primary-dark');
      background-image: none;
   }
}

.filtered-posts {
   .item > div {
      background-color: rgba(0, 0, 0, 0.05);
      padding: 1em;
   }
}


.cats {
   font-size: 0.8rem;
   margin: 0;
   & + h1 {
      margin-top: 0;
   }
}

#county-select {
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
   border: none;
   font-weight: 500;
   padding: 0.25rem 1rem 0.25rem 0.55rem;
   color: color("primary-dark");
   background-image: url('/wp-content/themes/mpc-timber/static/img/angle-arrow-down-primary.svg');
   background-position: right 0.5rem center;
   background-size: 0.5rem;
   background-repeat: no-repeat;
   cursor: pointer;
   /* For IE <= 11 */
   select::-ms-expand {
      display: none;
   }
}

.hl {
   background: #ffc600;
}

.alphabet-sort {
   list-style: none;
   padding-left: 0;
   display: flex;
   width: 100%;
   justify-content: space-between;
   li {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
         background-color: rgba(0, 0, 0, 0.05);
         text-decoration: none;
         padding: 0.2rem;
         width: 2rem;
         height: 2rem;
         text-align: center;
         color: color("primary-dark");
         transition: background-color 0.1s ease-in-out;
         &:hover {
            background-color: rgba(0, 0, 0, 0.025);
         }
         &.active {
            color: white;
            background-color: color("primary-dark");
         }
      }
   }
}

aside {
   margin: 1rem 0;
   .sidebar-widget {
      margin-bottom: 2rem;
   }
   @include at-media('tablet') {
      padding-left: 1.5rem;
   }
}

.intro-p {
   color: color("primary");
   font-weight: 500;
   font-size: 1.15rem;
   line-height: 1.6rem;
}

@import "layout-builder";

.content-wrapper {
   // margin: 1rem 0;
   margin-top: 1rem;
   margin-bottom: 1rem;
   .featured-image {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 4px 4px 0 0;
      height: rem(250px);
   }
   .article-body {
      margin: 1rem 0;
      // padding-right: 1rem;
      h2, h3 {
         font-family: $museo;
         color: color("primary-dark");
         line-height: line-height('heading', 1);
      }
      h4, h5, h6 {
         font-family: $proxima;
         line-height: line-height('heading', 2);
      }
      &.layout {
         @include layoutBuilder;
      }
      a:not(.usa-button) {
         color: color("primary-dark");
         &:hover {
            color: color("primary");
            text-decoration: none;
         }
      }
   }
}

.hero-content {
   display: flex;
   flex-direction: column;
   justify-content: center;
   height: 100%;
   // padding-bottom: calc(4.5vw + 5em);
   padding: 4rem 0 10rem;
   h1, p, a {
      color: white;
   }
   h1 {
      margin: 0;
      text-shadow: rem(1px) rem(1px) rem(8px) rgba(0, 0, 0, 0.1), rem(5px) rem(4px) rem(16px) rgba(0, 0, 0, 0.75), rem(5px) rem(4px) rem(22px) rgba(0, 0, 0, 0.35);
      &.usa-hero__heading {
         font-size: size('heading', $theme-type-scale-4xl);
         line-height: line-height('heading', 1);
      }
   }
   .hero-phone {
      text-decoration: none;
      font-weight: bold;
      font-family: $museo;
      font-size: 1.25rem;
      text-shadow: rem(1px) rem(1px) rem(8px) rgba(0, 0, 0, 0.1), rem(5px) rem(4px) rem(16px) rgba(0, 0, 0, 0.75), rem(5px) rem(4px) rem(22px) rgba(0, 0, 0, 0.35);
   }
   @include at-media($theme-header-min-width) {
      max-width: 50vw;
   }
}

@import "page-homepage";

.usa-footer {
   opacity: 0.25; // For proofing purposes
}

.accordionFAQ {
   & > div {
      margin-bottom: 0.75rem;
      box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.05);
      .question {
         padding: 0.8rem;
         display: block;
         padding-right: 4rem;
         padding-left: 1rem;
         font-size: 1.1rem;
         font-weight: 500;
         color: color("primary-dark");
         position: relative;
         background-color: rgba(255, 255, 255, 1);
         cursor: pointer;
         border: 1px solid rgba(112, 112, 112, 0.8);
         p {
            margin: 0;
         }
         &:after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: '\f055';
            position: absolute;
            right: 1rem;
            font-size: 1.25rem;
            top: 0;
            bottom: 0;
            color: color("primary-dark");
            display: flex;
            align-items: center;
            transition: $global-transition;
         }
         &:hover {
            &:after {
               color: color("primary");
            }
         }
      }
      .answer {
         padding: 0.8em;
         padding-left: 1rem;
         background-color: rgba(255, 255, 255, 0.6);
         border: 1px solid rgba(112, 112, 112, 0.8);
         border-top: none;
         // border-top: 1px solid rgba(0, 0, 0, 0.1);
         & + p:empty {
            display: none;
         }
      }
      // &:not(:first-of-type) {
      //    .question {
      //       border-top: 1px solid rgba(0, 0, 0, 0.1);
      //    }
      // }
   }
}

#gform_fields_4 {
	display: flex;
   flex-wrap: wrap;
   & > li {
      flex-basis: 50%;
      input {
         width: 100%;
      }
      &#field_4_5 {
         flex-basis: 100%;
      }
      @include at-media-max("tablet") {
         flex-basis: 100%;
      }
   }
}









// MPCMedicaid.com

.provider-hero {
   h1 {
      font-family: $museo;
      text-shadow: 0 2px 20px rgba(255, 255, 255, 0.75);
      color: $mpc-blue-dark;
      font-size: 2rem;
      span {
         color: $mpc-red;
         font-size: 3.5rem;
         .red-swoosh {
            color: $mpc-blue-dark;
            position: relative;
            &:after {
               content: "";
               background-image: url('/wp-content/uploads/2020/10/red-swash.svg');
               background-repeat: no-repeat;
               background-position: center center;
               background-size: contain;
               position: absolute;
               left: 0;
               right: 0;
               bottom: -1rem;
               height: 2rem;
               z-index: -1;
            }
         }
      }
   }
   @include at-media-max("tablet") {
      background-position: right;
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5)),url('https://mpcmedicaid.com/wp-content/uploads/2020/10/MPC-Provider-Portal-Hero1.jpg')!important;
   }
}

.red-swoosh {
   color: $mpc-blue-dark;
   position: relative;
   &:after {
      content: "";
      background-image: url('/wp-content/uploads/2020/10/red-swash.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1rem;
      height: 2rem;
      z-index: -1;
   }
}

.article-body.layout {
   .usa-card {
      .usa-card__container {
         position: relative;
         background-color: white;
         z-index: 1;
         border: none;
         &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
            border-radius: .4444444444rem;
         }
      }
   }
}

.cta-card {
   margin-bottom: 0;
   margin-top: 1rem;
   .usa-card__container {
      border: none;
      box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
   }
   .usa-card__body {
      padding: 1.5rem 1.5rem 2.75rem!important;
      display: flex;
      align-items: flex-start;
      p:empty {
         display: none;
      }
      img {
         max-width: 65px;
         margin-right: 2rem;
      }
      .cta-card-content {
         display: flex;
         // align-items: center;
         flex-direction: column;
         p {
            color: color("base-dark");
            font-size: 1.25rem;
            line-height: 1.3;
            margin: 0;
            strong {
               &.blue {
                  color: $mpc-blue-dark;
                  font-family: $museo;
                  font-size: 1.5rem;
                  span {
                     color: $mpc-red;
                  }
               }
               font-size: 1.25rem;
            }
            span.mpc-red {
               color: $mpc-red;
            }
         }
         a {
            color: $mpc-red;
         }
         h4 {
            font-family: $proxima!important;
            color: $mpc-blue-dark;
            margin-bottom: 0.1rem;
         }
      }
      @include at-media-max("tablet") {
         flex-direction: column;
         align-items: center;
         img {
            margin-bottom: 1rem;
            margin-right: 0;
         }
      }
   }
   @include at-media("desktop") {
      margin-left: 5rem;
   }
}

.header-text-card {
   @include at-media("desktop") {
      margin-left: 5rem;
   }
}

.last-cta-card {
   .usa-card__container {
      position: relative;
      z-index: 1;
      &:before {
         content: "";
         position: absolute;
         left: 0;
         right: 0;
         top: 90%;
         bottom: -3.5rem;
         z-index: -1;
         background: linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('/wp-content/uploads/2020/10/card-bottom-shadow-digital-pattern.svg');
         background-repeat: no-repeat!important;
         background-size: cover!important;
         background-position: center center!important;
      }
      .usa-card__body {
         background-color: white;
         border-radius: .4444444444rem;
      }
   }
}

ul.styled {
   list-style: none;
   position: relative;
   li {
       padding-left: 2.5rem;
       margin-left: -2.5rem;
       margin-bottom: 0.25rem;
       color: color("primary-dark");
       &:before {
           content: "\25FC";
           color: color("primary-dark");
           position: absolute;
           left: 1rem;
       }
   }
   &:before {
       content: "";
       position: absolute;
       left: 0.25rem;
       top: 0;
       bottom: 0;
       border-left: 2px dashed color("primary-light");
   }
}

section.prs {
   margin-bottom: 2rem;
   .prs-select-section {
      background-image: linear-gradient(90deg, white 0%, white 20%, transparent, white 80%), linear-gradient(0deg,rgba(0,0,0,.05),transparent,rgba(0,0,0,.05));
      padding: 2.5rem 0 4rem;
      margin-bottom: 2rem;
      .prs-select-column {
         display: flex;
         flex-direction: column;
         align-items: center;
         p {
            color: $mpc-red;
            font-family: $museo;
            font-size: 1.2rem;
            margin-bottom: 0;
            line-height: 1.1;
         }
         select {
            color: color("primary-dark");
            padding: 0.5rem 1rem;
            border-radius: 0.44rem;
         }
      }
   }
   .prs-main-col {
      padding-bottom: 8rem;
      border-bottom: 6px solid #f32735;
      position: relative;
      &:before {
         content: "";
         position: absolute;
         left: 0;
         right: 0;
         height: 6rem;
         bottom: 0;
         z-index: -1;
         background: linear-gradient(180deg,rgba(255,255,255,.5),#fff),url('/wp-content/uploads/2020/10/card-bottom-shadow-digital-pattern.svg');
         background-repeat: no-repeat!important;
         background-size: cover!important;
         background-position: center center!important;
      }

      .prs-card {
         .usa-card__container {
            border: none;
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1), 0 2px 10px rgba(0, 0, 0, 0.075);
            transition: transform $global-transition;
            .usa-card__header {
               background-color: rgba(0, 0, 0, 0.05);
               min-height: 4.5rem;
               border-radius: .4444444444rem .4444444444rem 0 0;
               p {
                  margin-left: 8rem;
                  margin-top: 1rem;
                  font-family: $museo;
                  color: color("primary-dark");
                  font-size: 1.25rem;
                  font-weight: 600;
                  line-height: 1.1;
               }
            }
            .usa-card__info {
               display: flex;
               padding-left: 1.5rem;
               padding-right: 1.5rem;
               img {
                  max-width: 7rem;
                  max-height: 7rem;
                  width: 100%;
                  height: auto;
                  margin-top: -3rem;
                  border-radius: 8px;
                  box-shadow: 0 5px 16px rgba(0,0,0,.2);
               }
               & > div {
                  margin-left: 1rem;
                  p {
                     margin-top: 0.5rem;
                     margin-bottom: 0;
                     color: color("primary-dark");
                     font-family: $museo;
                  }
                  a {
                     color: color("primary-dark")!important;
                     font-family: $proxima;
                     &:hover {
                        color: color("primary")!important;
                        text-decoration: none;
                     }
                  }
               }
            }
            .usa-card__body {
               padding-left: 1.5rem;
               padding-right: 1.5rem;
               p {
                  margin: 1rem 0;
                  line-height: 1.4;
                  font-family: $museo;
                  font-weight: 300;
                  font-size: 0.95rem;
                  color: rgba(0, 0, 0, 0.75);
               }
            }
            &:hover {
               transform: translateY(-0.2rem);
            }
         }
      }
   }
}