.usa-hero {
    position: relative;
    .under-swash {
        position: relative;
        &:after {
            content: "";
            background-image: url('/wp-content/uploads/2020/10/here-swash.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1rem;
            height: 2rem;
            z-index: -1;
        }
    }
    .hero-cta-button-container {
        position: absolute;
        margin: 0 calc(calc(100vw - 77.7777777778rem) / 2);
        top: calc(65% - 2vw);
        bottom: 0;
        left: 0;
        right: 45vw;
        display: flex;
        z-index: 1;
        a.hero-cta-button {
            background-color: color("primary-dark");
            color: white;
            font-family: $museo;
            font-size: 1.9rem;
            line-height: 1.45rem;
            text-decoration: none;
            height: 10rem;
            width: 10rem;
            padding: 0.5rem;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            font-weight: bold;
            border: 4px solid white;
            text-transform: uppercase;
            position: relative;
            box-shadow: 3px 10px 12px rgba(0, 0, 0, 0.15);
            // margin-right: 2rem;
            // margin-left: auto;
            margin: auto;
            transition: background-color $global-transition;
            z-index: 1;
            &:hover {
                background-color: color("primary");
            }
            span {
                font-size: 2.5rem;
                line-height: 2.15rem;
                display: block;
                margin: 0.2rem 0 0.5rem;
                position: relative;
                &:after {
                    content: "";
                    background-image: url('/wp-content/uploads/2020/10/blue-swash.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    position: absolute;
                    left: -0.05rem;
                    right: 0.15rem;
                    bottom: -1.1rem;
                    height: 1.5rem;
                    z-index: -1;
                }
            }
            &:after {
                content: "";
                position: absolute;
                bottom: -1.5rem;
                background-image: url('/wp-content/uploads/2020/10/finger-click.svg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                height: 3.5rem;
                width: 3.5rem;
                left: 3.5rem;
                right: 0;
                margin: auto;
            }
        }
    }
}

aside.home {
    @include at-media-max('tablet') {
        display: none;
    }
}

section#help {
    .help-header {
        background-color: color("primary-dark");
        background-image: url('/wp-content/uploads/2020/10/squares-bg.svg'), linear-gradient(0deg, color("primary-dark"), color("primary"));
        background-position: center center;
        background-size: contain;
        background-repeat: repeat-x;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.25rem 0;
        margin-bottom: 1rem;
        h2, p {
            color: white;
            margin: 0;
        }
        h2 {
            font-size: 2.5rem;
            font-family: $museo!important;
            text-shadow: 0px 0px 14px rgba(0, 49, 95, 60%);
        }
    }
    .help-options-container {
        margin-bottom: 2rem;
        .iam-container {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
            img {
                max-width: 4.5rem;
                height: auto;
                margin-right: 1rem;
                margin-top: auto;
                // margin-bottom: auto;
            }
            .iam {
                color: $mpc-red;
                font-weight: bold;
                font-size: 2rem;
                text-align: center;
                // margin: 0.5rem 0;
                margin-top: auto;
                margin-bottom: 0;
                line-height: 1;
            }
        }
        .grid-row {
            justify-content: center;
        }
        .help-option {
            margin-bottom: 1rem;
            & > a {
                border: 2px solid color("primary-light");
                border-radius: 8px;
                display: flex;
                padding: 1rem 2rem;
                text-decoration: none;
                font-family: $museo;
                color: color("primary-dark");
                font-weight: bold;
                font-size: 1.2rem;
                line-height: 1.15;
                height: 100%;
                align-items: center;
                transition: border-color $global-transition, background-color $global-transition;
                svg, img {
                    max-width: 3rem;
                    height: auto;
                    margin-right: 0.75rem;
                    width: 100%;
                    g {
                        fill: color("primary-light");
                        transition: fill $global-transition;
                    }
                }
                &:hover {
                    border-color: color("primary");
                    background-color: transparentize($mpc-blue-dark, 0.98);
                    svg {
                        g {
                            fill: color("primary");
                        }
                    }
                }
            }
        }
    }
    .help-cards-container {
        margin-bottom: 2rem;
       .help-card {
            & > div {
                border: none;
                @include u-shadow(3);
            }
            .usa-card__heading {
                color: color("primary-dark");
            }
       }
    }
}

section#info {
    .ad-col {
        display: flex;
        justify-content: center;
    }
}