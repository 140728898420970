@mixin layoutBuilder {
    .layout-heading-container {
        display: flex;
        align-items: center;
        .layout-heading-image-container {
            box-shadow: 0.6666666667rem 0 0.4444444444rem rgba(0,94,162,.1);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            margin-right: 1.25rem;
            width: 5rem;
            height: 5rem;
            flex-shrink: 0;
            margin-bottom: -3rem;
            img {
                max-width: rem(45px);
                height: auto;
                width: 100%;
                // margin-right: 0.75rem;
            }
        }
        & ~ hr {
            width: 75%;
        }
    }
    .layout-heading {
        color: color("primary-dark");
        margin-bottom: 0.15rem;
        margin-top: 0;
        &:not(h1) {
            margin-top: 3.5rem;
        }
    }
    h1 {
        font-family: $museo!important;
        font-size: 2.5rem;
        line-height: 2.5rem;
    }
    h2 {
        font-family: $museo!important;
        font-size: 2.15rem;
        line-height: 2.15rem;
        position: relative;
        &:before {
            content: "";
            border: 0.5rem solid transparent;
            border-top-color: $mpc-red;
            position: absolute;
            top: -1rem;
            left: 0.25rem;
        }
    }
    h3 {
        font-family: $museo!important;
        font-size: 1.8rem;
        line-height: 1.8rem;
    }
    h4 {
        font-family: $museo!important;
        font-size: 1.45rem;
        line-height: 1.45rem;
    }
    h5 {
        font-family: $museo!important;
        font-size: 1.1rem;
        line-height: 1.1rem;
    }
    .layout-subheading {
        font-size: 1.25rem;
        color: #5B5656;
    }
    .layout-list-heading {
        font-family: $museo!important;
        font-size: 1.35rem;
        margin-bottom: 0;
        color: color("primary");
        font-weight: 500;
        & + ol, & + ul {
            margin-top: 0.5rem;
            padding-left: 2.5rem;
            padding-right: 2rem;
        }
        & + ol {
            list-style: none;
            counter-reset: mpc-ol;
            li {
                counter-increment: mpc-ol;
                padding-bottom: 0.25rem;
                margin-bottom: 0.25rem;
                border-bottom: 1px solid #707070;
                padding-left: 2.5rem;
                margin-left: -2.5rem;
                &:before {
                    content: counter(mpc-ol) " :: ";
                    color: #707070;
                    font-weight: bold;
                    position: absolute;
                    left: 0.5rem;
                }
            }
        }
        & + ul {
            list-style: none;
            position: relative;
            li {
                padding-left: 2.5rem;
                margin-left: -2.5rem;
                color: color("primary-dark");
                &:before {
                    content: "\25FC";
                    color: color("primary-dark");
                    position: absolute;
                    left: 1rem;
                }
            }
            &:before {
                content: "";
                position: absolute;
                left: 0.25rem;
                top: 0;
                bottom: 0;
                border-left: 2px dashed color("primary-light");
            }
        }
    }
    .full-width-layout {
        width: 100vw;
        margin-left: calc(50% - 50vw);
        padding: 3rem 0;
        margin-top: 2rem;
        .content-container {
            h2:first-of-type {
                margin-top: 0;
            }
        }
        .layout-heading {
            margin-top: 1.5rem;
        }
        h2:before {
            content: none;
        }
        @include at-media('tablet') {
            margin-left: calc(25% - 50vw);
        }
        @include at-media('desktop') {
            margin-left: calc(50% - 50vw);
        }
    }
}