@mixin adBase {
   display: flex;
   flex-direction: column;
   position: relative;
   min-width: 14.25rem; // 250px
   max-width: 22rem; // 385px
   border-radius: 4px;
   @include u-shadow(3);
   height: fit-content;
   margin-bottom: 2rem;
}

//-------------//
// Medicaid Ad //
//-------------//
.medicaid-ad {
   @include adBase;
   & > div {
      background-color: white;
      &:nth-of-type(1) {
         flex: 3;
         border-radius: 4px 4px 0 0;
         border: 1px solid #707070;
         border-bottom: none;
         span {
            display: block;
            text-align: center;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            &.heading {
               font-size: 1.6rem;
               line-height: 1.6rem;
               padding-top: 1.25rem;
               margin-bottom: 0.5rem;
               color: #5a5555;
               font-family: $museo!important;
               font-weight: bold;
            }
            &.subtext {
               color: color("primary-dark");
               font-size: 0.95rem;
               line-height: 1.05rem;
               margin-bottom: 0.5rem;
            }
         }
         img {
            margin-bottom: -0.75rem;
            display: block;
         }
      }
      &:nth-of-type(2) {
         flex: 1;
         border: 1px solid $mpc-red;
         border-top: none;
         border-radius: 0 0 4px 4px;
         a, div.a {
            background-color: $mpc-red;
            color: white;
            text-decoration: none;
            text-align: center;
            display: block;
            text-transform: uppercase;
            padding: 0.35rem 0.25rem;
            transition: background-color $global-transition;
         }
         a:hover {
            background-color: darken($mpc-red, 10%);
         }
      }
   }
   &:before {
      content: "";
      width: 30%;
      height: 50%;
      background-image: url('/wp-content/uploads/2020/10/fingerprint.png');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
      margin: auto;
      z-index: -1;
      opacity: 75%;
   }
}

//---------//
// Quiz Ad //
//---------//
.quiz-ad {
   @include adBase;
   & > div {
      &:nth-of-type(1) {
         padding-left: 1.25rem;
         padding-right: 1.25rem;
         padding-top: 1rem;
         img {
            max-width: 5.4rem; // 95px
            height: auto;
            display: block;
         }
         span {
            &.heading {
               font-size: 2rem;
               line-height: 1.9rem;
               padding-top: 0.5rem;
               margin-bottom: 0.5rem;
               color: color("primary-dark");
               font-family: $museo!important;
               font-weight: bold;
               display: block;
               span {
                  color: $mpc-red;
                  font-family: $museo!important;
               }
            }
            &.subheading {
               font-size: 1.15rem;
               line-height: 1.25rem;
               display: block;
               color: #5A5555;
               font-weight: bold;
               margin-bottom: 0.25rem;
            }
            &.text {
               font-size: 0.95rem;
               line-height: 1.05rem;
               display: block;
               color: #5A5555;
            }
         }
      }
      &:nth-of-type(2) {
         background-image: url('/wp-content/uploads/2020/10/quiz-ad-bottom-graphic.png');
         background-size: contain;
         background-position: center bottom;
         background-repeat: no-repeat;
         min-height: 10.25rem; // 180px
         display: flex;
         align-items: center;
         justify-content: flex-end;
         a {
            background-color: color("primary-dark");
            color: white;
            font-family: $museo;
            font-size: 1.15rem;
            line-height: 1.15rem;
            text-decoration: none;
            height: 6rem;
            width: 6rem;
            padding: 0.5rem;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            font-weight: bold;
            border: 4px solid white;
            text-transform: uppercase;
            position: relative;
            margin-right: 2rem;
            margin-left: auto;
            transition: $global-transition;
            &:hover {
               background-color: color("primary");
            }
            span {
               z-index: 1;
               &.start {
                  position: relative;
                  margin-bottom: 0.5rem;
                  &:after {
                     content: "";
                     background-image: url(/wp-content/uploads/2020/10/blue-swash.svg);
                     background-repeat: no-repeat;
                     background-position: center center;
                     background-size: contain;
                     position: absolute;
                     left: .05rem;
                     right: .25rem;
                     bottom: -1.15rem;
                     height: 1.45rem;
                     z-index: -1;
                  }
               }
               &:not(.start) {
                  font-size: 0.75rem;
                  line-height: 0.9rem;
               }
            }
            &:after {
               content: "";
               position: absolute;
               bottom: -1.15rem;
               background-image: url('/wp-content/uploads/2020/10/finger-click.svg');
               background-repeat: no-repeat;
               background-position: center center;
               background-size: contain;
               height: 2.25rem;
               width: 2.25rem;
               left: 1.25rem;
               right: 0;
               margin: auto;
            }
         }
      }
   }
}

//-------------//
// Benefits Ad //
//-------------//
.benefits-ad {
   @include adBase;
   & > div {
      &.header {
         background-image: url('/wp-content/uploads/2020/10/benefits-ad-header.svg');
         background-position: center center;
         background-size: 50%;
         background-repeat: no-repeat;
         min-height: 6rem;
         margin: 0.5rem 0;
      }
      .mid-cta {
         color: $mpc-red;
         font-family: $museo;
         text-align: center;
         font-weight: bold;
         font-size: 1.05rem;
      }
      .bottom-cta {
         margin-bottom: 1rem;
         padding: 0 0.25rem;
         display: flex;
         flex-direction: column;
         align-items: center;
         img {
            height: 3rem;
            width: auto;
         }
         & > p {
            text-align: center;
            font-family: $museo;
            color: color("primary-dark");
            font-size: 0.8rem;
            line-height: 1.25;
            margin: 0;
            padding: 1rem 0.5rem;
            background-image: linear-gradient(0deg,#fff 0%,#d1d1d140 50%,#fff 100%);
            span {
               font-weight: bold;
               display: block;
            }
         }
      }
      span {
         &.heading {
            font-size: 2rem;
            line-height: 1.9rem;
            padding-top: 0.5rem;
            margin-bottom: 0.5rem;
            color: color("primary-dark");
            font-family: $museo!important;
            font-weight: bold;
            display: block;
            span {
               color: $mpc-red;
               font-family: $museo!important;
            }
         }
         &.subheading {
            font-size: 1.15rem;
            line-height: 1.25rem;
            display: block;
            color: #5A5555;
            font-weight: bold;
            margin-bottom: 0.25rem;
         }
         &.text {
            font-size: 0.95rem;
            line-height: 1.05rem;
            display: block;
            color: #5A5555;
         }
      }
      &.button-container {
         border-radius: 0 0 4px 4px;
         a {
            background-color: color("primary-dark");
            color: white;
            text-decoration: none;
            text-align: center;
            display: block;
            text-transform: uppercase;
            padding: 0.35rem 0.25rem;
            border-radius: 0 0 4px 4px;
            transition: background-color $global-transition;
            &:hover {
               background-color: darken(color("primary-dark"), 10%);
            }
         }
      }
      &:not(:first-of-type):not(.button-container) {
         padding-left: 1.25rem;
         padding-right: 1.25rem;
      }
   }
}